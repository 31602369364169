export const validateVariable = (x, v) => {
  const validateArray = ['', null, undefined];
  console.log(validateArray, '@validate variable');
  return !validateArray.includes(x) ? x : v ? v : undefined;
};

// export const validateData = obj => {
//   for (const key in obj) {
//     obj[key] !== '' ? obj[key] : undefined;
//   }
// };
