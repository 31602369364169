import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  matchesByJob: null,
  allMatches: null,
};

export const MatchSlice = createSlice({
  name: 'matchSlice',
  initialState,
  reducers: {
    setMatchesByJob: (state, { payload }) => {
      state.matchesByJob = payload;
    },

    setAllMatches: (state, { payload }) => {
      state.allMatches = payload;
    },

    setAddMatch: (state, { payload }) => {
      state.matches?.matches.push(payload);
    },

    setUpdateMatch: (state, { payload }) => {
      const matchIndex = state.matchesByJob?.matches?.findIndex(match => match?._id === payload?._id);
      console.log({ matchIndex, payload }, '@status redux');
      if (matchIndex >= 0) {
        state.matchesByJob.matches[matchIndex] = payload;
        // state.all.matches[matchIndex] = payload;
      }
    },
    setDeleteMatchByJob: (state, { payload }) => {
      const updatedMatches = state.matchesByJob.matches.filter(item => item._id !== payload._id);
      state.matchesByJob.matches = updatedMatches;
    },
  },
});

export const { setAllMatches, setMatchesByJob, setDeleteMatchByJob, setUpdateMatch, setAddMatch, setMatchNotification } = MatchSlice.actions;

export default MatchSlice.reducer;
