import axios from 'axios';

import { useSelector } from 'react-redux';

import { store } from '../../Store';
import { setAuthStep, setBusiness, setConsulationStep, setSession, setUser, userLoggedOut } from '../../Store/Slices/AuthSlice';
import ApiService from '../../Services/ApiService';
import { PERSIST_SESSION_NAME } from '../../Constants/app.constants';
import BaseService from '../../Services/BaseService';
import ToastController from '../ToastController';
import UtilityController from '../UtilityController';
import EnumsController from '../EnumsController';
import MySocketController from '../SocketController';
import NotificationController from '../NotificationController';
import { useEffect, useState } from 'react';
import { validateVariable } from '../../Validation';

class AuthController {
  /**
   * @param { SIGN_IN | VERIFY_OTP | SIGN_UP } modal
   */
  static handleToggleAuth(modal) {
    store.dispatch(setAuthStep(modal));
  }
  static handleBusinessData(data) {
    store.dispatch(setBusiness(data));
  }
  static handleAuthUser(data) {
    store.dispatch(setUser(data));
  }
  // static ACCOUNT_STATUS = {
  //   APPROVED: 'approved',
  //   PENDING: 'pending',
  //   BLOCK: 'block',
  //   DRAFT: 'draft',
  //   REJECTED: 'rejected',
  // };

  static login(data = { email: '', password: '' }) {
    return new Promise((resolve, reject) => {
      ApiService.fetchData({
        url: '/employer/auth/login',
        method: 'post',
        data,
      })
        .then(res => {
          // const session = {
          //   user: { ...res?.data },
          //   token: res?.data?.token,
          // };
          resolve(res?.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  static loginV2(data = { email: '', password: '' }) {
    return new Promise((resolve, reject) => {
      ApiService.fetchData({
        url: '/employer/auth/v1/login',
        method: 'post',
        data,
      })
        .then(res => {
          resolve(res?.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  static claimEmployer(data = { email: '', npiNumber: '', organization: '' }) {
    return ApiService.fetchData({
      url: '/employer/auth/claim',
      method: 'post',
      data,
    });
  }

  static syncUser(userId) {
    return ApiService.fetchData({
      url: `/employer/auth/sync-user-detail/${userId}`,
      method: 'get',
    });
  }

  static verifyOtp(data = { email: '', otp: '' }) {
    return ApiService.fetchData({
      // url: '/employer/auth/verify-otp',
      url: '/employer/auth/v1/verify-otp',
      method: 'post',
      data,
    });
  }

  static registerUser(data = { email: '', password: '' }) {
    return ApiService.fetchData({
      url: '/employer/auth/v1/register',
      method: 'post',
      data,
    });
  }

  static updateOrganizationDetail(data, id) {
    console.log(data, id, '@organozation data');
    const formatedData = {
      logo: data?.logo,
      organizationName: validateVariable(data?.organizationName),
      practiceManagerName: validateVariable(data?.managerName),
      email: validateVariable(data?.email),
      phoneNumber: validateVariable(data?.phoneNumber),
      orgLocations: {
        locations: data?.locations?.description
          ? [
              {
                description: data?.locations?.description,
                coords: { longitude: data?.locations?.coords?.longitude ?? data?.locations?.coords?.lng, latitude: data?.locations?.coords?.latitude ?? data?.locations?.coords?.lat },
              },
            ]
          : [],
        numberOfLocations: validateVariable(data?.numberOfLocations),
        // cityState: {
        //   description: data?.cityState,
        //   coords: { longitude: data?.locations?.coords?.lng, latitude: data?.locations?.coords?.lat },
        // },
      },
      features: {
        practiceType: validateVariable(data?.practiceSetting),
        noOfPhysician: validateVariable(data?.numberOfPhysician),
        nonPhysicianProvider: validateVariable(data?.numberOfNonPhysician),
        aboutUs: validateVariable(data?.overView),
      },
      media: validateVariable(
        data?.media?.map(ls => {
          const { _id, ...rest } = ls;
          return rest;
        })
      ),
      socialLinks: {
        facebook: validateVariable(data?.facebook),
        twitter: validateVariable(data?.twitter),
        instagram: validateVariable(data?.instagram),
        websiteUrl: validateVariable(data?.websiteUrl),
      },
    };
    // const formData = new FormData();
    // formData.append('data', formatedData);
    // formData.append('logo', data?.logo);
    return ApiService.fetchData({
      url: `/employer/auth/v1/update-orgdetails/${id}`,
      method: 'patch',
      data: formatedData,
    });
  }
  static createJob(data, id) {
    // console.log(data, '@data create job');
    // const formatedData = {
    //   title: validateVariable(data?.title),
    //   specialty: data?.specialty,
    //   subSpecialty: data?.subSpecialty,
    //   employmentType: validateVariable(data?.employmentType),
    //   reasonForJobOpp: validateVariable(data?.reasonForJobOpp),
    //   salaryRange: validateVariable(data?.salaryRange),
    //   signOnBonus: validateVariable(data?.signOnBonus),
    //   productiveBonus: validateVariable(data?.productiveBonus),
    //   stipendTraining: validateVariable(data?.stipendTraining),
    //   loanRepaymentAssistance: validateVariable(data?.loanRepaymentAssistance),
    //   vacation: validateVariable(data?.vacation),
    //   callSchedule: validateVariable(data?.callSchedule),
    //   requiredTravel: validateVariable(data?.requiredTravel),

    //   avgClinicVisitPerDay: validateVariable(data?.avgClinicVisitPerDay),
    //   avgSurgicalVolume: validateVariable(data?.averageSurgicalVolumePerWeek),
    //   advancedTech: validateVariable(data?.advancedTech),
    //   electronicMedRecord: validateVariable(data?.electronicMedRecord),
    //   scribeSupport: validateVariable(data?.scribeSupport),
    //   supportBenefitPerk: validateVariable(data?.fringeBenefits?.map(ls => ls?.title)),

    //   partnership: {
    //     value: validateVariable(data?.partnershipOpportunity),
    //     types: data?.partnershipOpportunity === 'Yes' ? [data?.provenPartnershipTrack, data?.practiceASCRealEstate] : [],
    //   },
    //   academicResearchOpportunity: validateVariable(data?.academicOpportunity),
    //   mentorship: validateVariable(data?.mentorship),
    //   speakerShip: validateVariable(data?.speakerShip),
    //   globalMissions: validateVariable(data?.globalMissions),
    // };
    const copyData = { ...data, salaryRange: JSON.parse(data?.salaryRange), signOnBonus: JSON.parse(data?.signOnBonus) };
    console.log(copyData, '@data auth create job');

    const formatedData = {
      title: validateVariable(copyData?.title),
      specialty: copyData?.specialty,
      subSpecialty: copyData?.subSpecialty,
      employmentType: validateVariable(copyData?.employmentType),
      reasonForJobOpp: validateVariable(copyData?.reasonForJobOpp),
      salaryRange: validateVariable(copyData?.salaryRange),
      signOnBonus: validateVariable(copyData?.signOnBonus),
      productiveBonus: validateVariable(copyData?.productiveBonus),
      stipendTraining: validateVariable(copyData?.stipendTraining),
      loanRepaymentAssistance: validateVariable(copyData?.loanRepaymentAssistance),
      vacation: validateVariable(copyData?.vacation),
      callSchedule: validateVariable(copyData?.callSchedule),
      requiredTravel: validateVariable(copyData?.requiredTravel),

      avgClinicVisitPerDay: validateVariable(copyData?.avgClinicVisitPerDay),
      avgSurgicalVolume: validateVariable(copyData?.averageSurgicalVolumePerWeek),
      advancedTech: validateVariable(copyData?.advancedTech),
      electronicMedRecord: validateVariable(copyData?.electronicMedRecord),
      scribeSupport: validateVariable(copyData?.scribeSupport),
      supportBenefitPerk: validateVariable(copyData?.fringeBenefits?.map(ls => ls?.title)),

      partnership: {
        value: validateVariable(copyData?.partnershipOpportunity),
        types: copyData?.partnershipOpportunity === 'Yes' ? [copyData?.provenPartnershipTrack, copyData?.practiceASCRealEstate] : [],
      },
      academicResearchOpportunity: validateVariable(copyData?.academicOpportunity),
      mentorship: validateVariable(copyData?.mentorship),
      speakerShip: validateVariable(copyData?.speakerShip),
      globalMissions: validateVariable(copyData?.globalMissions),
      // locations: validateVariable(copyData?.locations?.map(({ key, _id, ...rest }) => rest)),
      locations: validateVariable(
        copyData?.locations?.map((ls, i) => {
          return {
            city: ls?.city || '',
            state: ls?.state || '',
            description: ls?.description,
            coords: { latitude: ls?.coords?.latitude || ls?.coords?.lat, longitude: ls?.coords?.longitude || ls?.coords?.lng },
          };
        })
      ),
    };
    return ApiService.fetchData({
      url: `/employer/auth/v1/create-job-v1/${id}`,
      method: 'post',
      data: formatedData,
    });
  }
  static generateLocationDetail(data) {
    const formatedData = {
      location: validateVariable(data),
    };
    console.log(formatedData, '@location title');
    return ApiService.fetchData({
      url: `/employer/auth/v1/generate-location`,
      method: 'post',
      data: formatedData,
    });
  }
  static updateLocationDetails(data, _id) {
    const formatedData = {
      locationsDetails: validateVariable(data),
    };
    console.log(formatedData, '@location formated data');
    return ApiService.fetchData({
      url: `/employer/auth/v1/update-locationDetails/${_id}`,
      method: 'patch',
      data: formatedData,
    });
  }

  static updateCandidatePref(data, _id) {
    const homeStatelat = data?.homeState?.coords?.latitude ?? data?.homeState?.coords?.lat;
    const homeStatelng = data?.homeState?.coords?.longitude ?? data?.homeState?.coords?.lng;
    const formatedData = {
      candidatePreferences: {
        specialty: data?.specialty,
        subSpecialty: data?.subSpecialty,
        // homeState: {
        //   description: data?.homeState?.description,
        //   coords: { longitude: homeStatelng, latitude: homeStatelat },
        // },
        homeState: data?.homeState?.map((ls, index) => {
          return {
            description: ls?.description,
            coords: { longitude: ls?.coords?.latitude || ls?.coords?.lat, latitude: ls?.coords?.longitude ?? ls?.coords?.lng },
          };
        }),
        boardCertStatus: validateVariable(data?.boardCertificationStatus),
        minExperience: validateVariable(data?.minimumExp),
        yearAvailableToStart: validateVariable(data?.yearAvailableStart),
      },
    };

    return ApiService.fetchData({
      url: `/employer/auth/v1/update-candidate-preference/${_id}`,
      method: 'patch',
      data: formatedData,
    });
  }
  static updateProfileStep(data, _id) {
    const formatedData = {
      currentProfileStep: validateVariable(data?.currentProfileStep),
    };
    return ApiService.fetchData({
      url: `/employer/auth/v1/update-current-profile-step/${_id}`,
      method: 'patch',
      data: formatedData,
    });
  }

  static setupPassword(data = { email: '', password: '' }) {
    return ApiService.fetchData({
      url: '/employer/auth/set-password',
      method: 'post',
      data,
    });
  }

  static sendOtpRecoverPassword(data = { email: '' }) {
    console.log(data, '@recover email');
    return ApiService.fetchData({
      url: '/employer/auth/v1/send-otp-password-recovery',
      method: 'post',
      data,
    });
  }

  static verifyOtpRecoverPassword(data = { email: '', otp: '' }) {
    console.log(data, '@verify otp');
    return ApiService.fetchData({
      url: '/employer/auth/v1/verify-otp-password-recovery',
      method: 'post',
      data,
    });
  }
  static updateRecoverPassword(data = { password: '' }) {
    return ApiService.fetchData({
      url: '/employer/auth/v1/update-password-by-otp',
      method: 'post',
      data,
    });
  }

  static updateProfileV2(employerId, data) {
    console.log(data, '@edit submit start data update');
    const lat = data?.locations?.coords?.latitude ?? data?.locations?.coords?.lat;
    const lng = data?.locations?.coords?.longitude ?? data?.locations?.coords?.lng;
    const homeStatelat = data?.homeState?.coords?.latitude ?? data?.homeState?.coords?.lat;
    const homeStatelng = data?.homeState?.coords?.longitude ?? data?.homeState?.coords?.lng;
    const updateData = {
      deletedImgskeys: validateVariable(data?.deletedImgskeys),
      logo: data?.logo,
      organizationName: validateVariable(data?.organizationName),
      practiceManagerName: validateVariable(data?.managerName),
      email: validateVariable(data?.email),
      phoneNumber: validateVariable(data?.phoneNumber),
      locationsDetails: data?.locationsDetails,
      orgLocations: {
        locations: [
          {
            description: data?.locations?.description,
            coords: { longitude: lng, latitude: lat },
          },
        ],
        numberOfLocations: validateVariable(data?.numberOfLocations),
      },

      features: {
        practiceType: validateVariable(data?.practiceSetting),
        noOfPhysician: validateVariable(data?.numberOfPhysician),
        nonPhysicianProvider: validateVariable(data?.numberOfNonPhysician),
        aboutUs: validateVariable(data?.aboutUs),
      },
      media: validateVariable(data?.Photo_Videos),
      socialLinks: {
        facebook: validateVariable(data?.facebook),
        twitter: validateVariable(data?.twitter),
        instagram: validateVariable(data?.instagram),
        websiteUrl: validateVariable(data?.websiteUrl),
      },
      candidatePreferences: {
        specialty: data?.specialty,
        subSpecialty: data?.subSpecialty,
        // homeState: {
        //   description: data?.homeState?.description,
        //   coords: { longitude: homeStatelng, latitude: homeStatelat },
        // },
        homeState: data?.homeState?.map((ls, index) => {
          return {
            description: ls?.description,
            coords: { longitude: ls?.coords?.latitude || ls?.coords?.lat, latitude: ls?.coords?.longitude ?? ls?.coords?.lng },
          };
        }),
        boardCertStatus: data?.boardCertificationStatus,
        minExperience: validateVariable(data?.minimumExp),
        yearAvailableToStart: validateVariable(data?.yearAvailableStart),
      },
    };
    console.log(updateData, '@edit submit data update');
    return ApiService.fetchData({
      url: `/employer/auth/v1/update-profile/${employerId}`,
      method: 'patch',
      data: updateData,
    });
  }
  static deleteAccount(employerId) {
    console.log(employerId, '@employer id to delete account');
    return ApiService.fetchData({
      url: `/employer/auth/v1/archive-profile/${employerId}`,
      method: 'patch',
      // data: updateData,
    });
  }
  static updatePassword(employerId, data) {
    const updateData = {
      oldPassword: data?.oldPassword,
      newPassword: data?.newPassword,
    };
    console.log(employerId, updateData, '@employer id to delete account');
    return ApiService.fetchData({
      url: `/employer/auth/v1/reset-password/${employerId}`,
      method: 'post',
      data: updateData,
    });
  }
  static updateProfile(employerId, data) {
    const updateData = {
      logo: data?.logo,
      organizationName: data?.organizationName,
      // city: data?.city,
      // state: data?.state,
      // zipCode: data?.zipCode,
      practiceType: data?.practiceType,
      specialty: data?.specialty,
      noOfPhysician: data?.numberOfPhysicianProviders,
      practiceManagerName: data?.practiceManager,
      managingPartner: data?.managingPartner,
      // nonPhysicianProvider: {
      //   midLevel: data?.midLevelProviders,
      //   nonPhysician: data?.nonPhysicianDoctors,
      // },
      // specialty: data?.specialty?.map(item => item?._id),
      subSpecialty: data?.subSpecialty,
      phoneNumber: data?.phoneNumber,
      media: data?.media,
      socialLinks: {
        facebook: data?.facebookLink,
        twitter: data?.twitterLink,
        linkedin: data?.linkedInLink,
      },
      aboutUs: data?.aboutUs,
      deletedImgskeys: data?.deletedImgskeys,
      locations: data?.locations,
    };
    return ApiService.fetchData({
      url: `/employer/auth/update-profile/${employerId}`,
      method: 'patch',
      data: updateData,
    });
  }

  static doximityRedirect(employeeId) {
    return ApiService.fetchData({
      url: `https://api-matchmed-dev.herokuapp.com/employee/auth/v1/exchange-code-with-token/${employeeId}`,
      method: 'get',
    });
  }

  static async handleUpdateProfile(data, employerId) {
    try {
      const singleMedial = !!data?.logo && (await UtilityController.uploadSingleMedial(data.logo));
      const multipleMedial = data?.Photo_Videos?.length > 0 && (await UtilityController.uploadMultipleMedial(data?.Photo_Videos));
      const logo = singleMedial?.data;
      const media = multipleMedial?.data;

      const updateData = { ...data, logo, media };

      const res = await AuthController.updateProfile(updateData);
    } catch (error) {}
    // if (!!singleMedial) {
    //   promises.push(UtilityController.uploadSingleMedial(singleMedial));
    // }

    // if (!!multipleMedial && multipleMedial?.length > 0) {
    //   UtilityController.uploadMultipleMedial(multipleMedial);
    // }

    // Promise.all(promises)
    //   .then(res => {})
    //   .catch(err => {});
  }

  static handleConsultationtoggle(bolen) {
    store.dispatch(setConsulationStep(bolen));
  }

  static persistCredentials = session => {
    if (!!session) {
      // session?.user && MySocketController.init();
      store.dispatch(setSession(session));
      BaseService.defaults.headers.common['Authorization'] = session?.token;
      localStorage?.setItem(PERSIST_SESSION_NAME, JSON.stringify(session));
    }
  };
  static persistTourGuide = data => {
    localStorage?.setItem('isTourComplete', data);
  };
  static getPersistedCredentials = () => {
    let strSessionData = localStorage?.getItem(PERSIST_SESSION_NAME);
    if (strSessionData) {
      return JSON.parse(strSessionData);
    } else {
      return null;
    }
  };

  static restorePersistedCredentials() {
    let persistedData = AuthController.getPersistedCredentials();
    if (persistedData?.user) {
      // MySocketController.init();
    }
    console.log(persistedData, '@persisted data.!');
    if (persistedData) {
      store.dispatch(setSession(persistedData));
      BaseService.defaults.headers.common['Authorization'] = persistedData?.token;
      return persistedData?.user;
    } else {
      return false;
    }
  }

  static logoutv2() {
    store.dispatch(userLoggedOut());
    localStorage?.removeItem(PERSIST_SESSION_NAME);
    localStorage.removeItem('@is-delegate');
  }

  static async logout(isShowToast) {
    const toast = new ToastController();
    const user = store.getState().Auth.session?.user;
    const firebaseToken = store.getState().Notification.fireBaseToken;
    try {
      !isShowToast && toast.showProcessing(`Logging out!`);
      await NotificationController.removeToken(user?._id, firebaseToken);
      store.dispatch(userLoggedOut());
      localStorage?.removeItem(PERSIST_SESSION_NAME);
      localStorage.removeItem('@is-delegate');
      axios.defaults.headers.common['Authorization'] = undefined;
      !isShowToast && toast.endProcessingWithSuccess('Logged out!');
    } catch (error) {
      store.dispatch(setSession(null));
      localStorage?.removeItem(PERSIST_SESSION_NAME);
      store.dispatch(userLoggedOut());
      axios.defaults.headers.common['Authorization'] = undefined;
      !isShowToast && toast.endProcessingWithError(error);
    }
  }

  static getCurrentUser() {
    const user = store.getState().Auth?.session?.user;

    if (!!user) {
      return user;
    }
    return null;
  }
}

export default AuthController;

// AuthController.handleToggleAuth('');

export const useAuth = () => {
  const session = useSelector(state => state.Auth.session);
  console.log(session, '@session useAuth');
  const isTourComplete = localStorage.getItem('isTourComplete');
  const isPending = session?.user?.accountStatus === EnumsController.ACCOUNT_STATUS.PENDING;
  const isBlockedRejected = session?.user?.accountStatus === EnumsController.ACCOUNT_STATUS.REJECTED;
  const loginRedirect = isBlockedRejected ? AuthController.logout() : isPending ? (session?.code === 201 ? '/establish-profile' : '/home') : '/home';
  const logOutRedirect = '/';

  return {
    isTourComplete: isTourComplete,
    user: session?.user,
    token: session?.token,
    authenticated: session?.token && session?.user ? true : false,
    session,
    loginRedirect,
    logOutRedirect,
  };
};
