import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  toggle: true,
};

export const LayoutSlice = createSlice({
  name: 'LayoutSlice',
  initialState,
  reducers: {
    setToggle: (state, { payload }) => {
      state.toggle = payload;
    },
  },
});

export const { setToggle } = LayoutSlice.actions;

export default LayoutSlice.reducer;
