import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  applicants: null,
  recommendedApplicants: null,
  savedApplicants: null,
  singleApplicant: null,
};

export const ApplicantSlice = createSlice({
  name: 'applicantSlice',
  initialState,
  reducers: {
    setApplicants: (state, { payload }) => {
      state.applicants = payload;
    },
    setRecommendedApplicants: (state, { payload }) => {
      state.recommendedApplicants = payload;
    },

    setSingleApplicant: (state, { payload }) => {
      state.singleApplicant = payload;
    },
    setSavedApplicants: (state, { payload }) => {
      state.savedApplicants = payload;
    },
    removeSavedApplicant: (state, { payload }) => {
      const filterd = state.savedApplicants.filter(item => item?._id !== payload?._id);
      state.savedApplicants = filterd;
    },

    setUpdateApplicant: (state, { payload }) => {
      const applicantIndex = state.applicants?.applicants?.findIndex(applicant => applicant?._id === payload?._id);
      const savedApplicantIndx = state.savedApplicants?.findIndex(applicant => applicant?._id === payload?._id);
      const recommendedApplicantIndex = state.recommendedApplicants?.applicants?.findIndex(applicant => applicant?._id === payload?._id);

      console.log({ applicantIndex, payload, applicant: state.applicants, savedApplicants: state.savedApplicants }, '@status redux');
      if (applicantIndex >= 0) {
        state.applicants.applicants[applicantIndex] = payload;
      }
      if (savedApplicantIndx >= 0) {
        state.savedApplicants[savedApplicantIndx] = payload;
      }
      if (recommendedApplicantIndex >= 0) {
        state.recommendedApplicants.applicants[recommendedApplicantIndex] = payload;
      }

      if (state.singleApplicant?._id === payload?._id) {
        state.singleApplicant = payload;
      }
    },
  },
});

export const { setApplicants, removeSavedApplicant, setSingleApplicant, setRecommendedApplicants, setUpdateApplicant, setSavedApplicants } = ApplicantSlice.actions;

export default ApplicantSlice.reducer;
