import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  metaData: null,
};

export const UtilitySlice = createSlice({
  name: 'utilitySlice',
  initialState,
  reducers: {
    setMetaData: (state, { payload }) => {
      state.metaData = payload;
    },
  },
});

export const { setMetaData } = UtilitySlice.actions;

export default UtilitySlice.reducer;
