// firebaseConfig.js
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging ,isSupported } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAYVI8Fsqie4mPrYUvS5_vHHH9G2Rs6FdU',
  authDomain: 'matchmed-4957c.firebaseapp.com',
  projectId: 'matchmed-4957c',
  storageBucket: 'matchmed-4957c.appspot.com',
  messagingSenderId: '481390800405',
  appId: '1:481390800405:web:d143d403ff3e8c3331681c',
  measurementId: 'G-7BZKXY3TLE',
};

export const messagingKey = 'BFHlyX8b2zFLEfrwh_jjJ0ee6_pVn4citmBmJHALOU7ocmMNVMwKJt5u018-PjAdJYqfGZsUVvTzKlTdACpA4D4';
export const isMessgingSupported = await isSupported()
// Initialize Firebase
const firebaseApp = isMessgingSupported ? initializeApp(firebaseConfig) : {};
export const messaging = isMessgingSupported ? getMessaging(firebaseApp) : null;
