import React from 'react';

const authRoute = [
  {
    key: 'signIn',
    path: `/signIn`,
    component: React.lazy(() => import('../../View/Auth/SignInView')),
    authority: [],
  },

  {
    key: 'loginIndex',
    path: `/`,
    component: React.lazy(() => import('../../View/Auth/LoginIndex')),
    authority: [],
  },
  {
    key: 'claimBusiness',
    path: `/claimBusiness`,
    component: React.lazy(() => import('../../View/Auth/ClaimBusinessView')),
    authority: [],
  },
  {
    key: 'SetUpPassword',
    path: `/setupPassword`,
    component: React.lazy(() => import('../../View/Auth/Register')),
    authority: [],
  },
  {
    key: 'Register',
    path: `/register`,
    component: React.lazy(() => import('../../View/Auth/RegisterV2')),
    authority: [],
  },
  {
    key: 'Register',
    path: `/recover-password`,
    component: React.lazy(() => import('../../View/Auth/RecoverPasswordView')),
    authority: [],
  },
  {
    key: 'Register',
    path: `/set-up-password`,
    component: React.lazy(() => import('../../View/Auth/UpdatePasswordView')),
    authority: [],
  },
  // {
  //   key: 'establishProfile',
  //   path: `/establish-profile`,
  //   component: React.lazy(() => import('../../View/EstablishProfile')),
  //   authority: [``],
  // },
];

export default authRoute;
