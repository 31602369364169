import Auth from './AuthSlice';
import Organization from './OrganizationSlice';
import LayoutSlice from './LayoutSlice';
import Applicant from './ApplicantSlice';
import Job from './JobSlice';
import Match from './MatchSlice';
import Conversation from './ConversationSlice';
import Notification from './NotificationSlice';
import Utility from './UtilitySlice';

export const rootReducer = {
  Auth,
  Organization,
  Applicant,
  LayoutSlice,
  Job,
  Match,
  Conversation,
  Notification,
  Utility,
};
