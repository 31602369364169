import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  conversations: null,
  singleConversation: null,
};

export const ConversationSlice = createSlice({
  name: 'conversationSlice',
  initialState,
  reducers: {
    setConversations: (state, { payload }) => {
      state.conversations = payload;
    },

    setSingleConversation: (state, { payload }) => {
      state.singleConversation = payload;
    },

    setUpdateConversation: (state, { payload }) => {
      const conversationIndex = state.conversations?.conversations?.findIndex(conversation => conversation?._id === payload?._id);
      console.log({ conversationIndex, payload }, '@status redux');
      if (conversationIndex >= 0) {
        state.conversations.conversations[conversationIndex] = payload;
      }
    },

    setAddNewMessage: (state, { payload }) => {
      const chatId = payload.chatId;
      const message = payload.message;
      console.log(payload, message, '@payload chat add message');
      const chatIndx = state?.conversations?.findIndex(chat => chat._id === payload.chatId);
      if (chatId === state?.singleConversation?._id) {
        state.singleConversation.messages.push(message);
        state.singleConversation.lastMessage = message;
      }
      if (chatIndx >= 0) {
        state.conversations[chatIndx].messages.push(payload.message);
        state.conversations[chatIndx].lastMessage = payload.message;
      }
    },

    setDeleteMessage: (state, { payload }) => {
      const { conversationId: chatId, messageId } = payload;

      return {
        ...state,
        conversations: state.conversations.map(chat => {
          if (chat._id === chatId) {
            const messageIndex = chat.messages.findIndex(msg => msg._id === messageId);
            if (messageIndex >= 0) {
              const updatedMessages = [...chat.messages];
              updatedMessages[messageIndex] = { ...updatedMessages[messageIndex], isDeleted: true };

              return {
                ...chat,
                messages: updatedMessages,
                lastMessage: chat.lastMessage?._id === messageId ? updatedMessages[messageIndex] : chat.lastMessage,
              };
            }
          }
          return chat;
        }),
        singleConversation:
          state.singleConversation?._id === chatId
            ? {
                ...state.singleConversation,
                messages: state.singleConversation.messages.map(msg => (msg._id === messageId ? { ...msg, isDeleted: true } : msg)),
                lastMessage:
                  state.singleConversation.lastMessage?._id === messageId
                    ? state.singleConversation.messages.length > 0
                      ? { ...state.singleConversation.messages[state.singleConversation.messages.length - 1] }
                      : null
                    : state.singleConversation.lastMessage,
              }
            : state.singleConversation,
      };
    },

    setDeleteConversation: (state, { payload }) => {
      const filtered = state.conversations.filter(conversation => conversation?._id !== payload?._id);
      state.conversations = filtered;
    },

    setUpdateMessage: (state, { payload }) => {
      const chatId = payload.chatId;
      const message = payload.message;
      console.log({ payload }, '@update message');
      // const msgId = payload.message.tempMsgId ?? payload.message._id;
      const messageIndx = state.singleConversation.messages.findIndex(msg => msg._id === message?.tempMsgId);
      const chatIndx = state.conversations.findIndex(chat => chat._id === chatId);
      if (chatId === state.singleConversation._id) {
        state.singleConversation.messages[messageIndx] = message;
      }

      if (chatIndx >= 0) {
        state.conversations[chatIndx].messages[messageIndx] = message;
      }
    },

    setReadConversation: (state, { payload }) => {
      if (!!state.conversations) {
        console.log('@running read Conversation');
        const conversationId = payload?._id;
        const conversationIndex = state.conversations.findIndex(item => item?._id === conversationId);
        const updatedConversation = { ...state.conversations[conversationIndex], employerCount: 0 };
        state.conversations[conversationIndex] = updatedConversation;
      }
    },

    setPushConversationCount: (state, { payload }) => {
      console.log('@running outside push count', payload);
      if (!!state.conversations && payload?.data?.conversation !== state?.singleConversation?._id) {
        console.log('@running push conversation count', payload);
        const conversationId = payload?.data?.conversation;
        const conversationIndex = state.conversations.findIndex(item => item?._id === conversationId);
        const prevCount = state.conversations[conversationIndex]?.employerCount;
        const updatedConversation = { ...state.conversations[conversationIndex], employerCount: prevCount + 1 };
        state.conversations[conversationIndex] = updatedConversation;
      }
    },
  },
});

export const {
  setPushConversationCount,
  setDeleteMessage,
  setReadConversation,
  setConversations,
  setDeleteConversation,
  setSingleConversation,
  setUpdateConversation,
  setAddNewMessage,
  setUpdateMessage,
} = ConversationSlice.actions;

export default ConversationSlice.reducer;
