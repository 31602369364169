import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  organizations: null,
};

export const OrganizationSlice = createSlice({
  name: 'organizationSlice',
  initialState,
  reducers: {
    setOrganizations: (state, { payload }) => {
      state.organizations = payload;
    },
  },
});

export const { setOrganizations } = OrganizationSlice.actions;

export default OrganizationSlice.reducer;
