import { SmallLogo } from '../Ui/Logo';

const SplashScreen = () => {
  return (
    <div className="flex flex-auto flex-col h-[100vh]">
      <div className="flex flex-col m-auto items-center max-w-md">
        <div className="relative mb-12">
          <div className="absolute rounded-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 animate-ZoomOut bg-secondary content-[' ']"></div>
          {/* <div className="absolute rounded-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 animate-ZoomOut1 bg-secondary content-[' ']"></div> */}
          <SmallLogo className={` !max-w-[70px] !w-full -translate-y-[35%]`} colored={true} navigateUrl={` `} />
        </div>
        {/* <Loading loading={true} /> */}
        {/* <AuthVectorImage /> */}
      </div>
    </div>
  );
};
export default SplashScreen;
