import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  authStep: false,
  session: null,
  user: null,
  consultationStep: false,
  business: {},
};

export const AuthSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setModalToggle: (state, { payload }) => {
      state.modalToggle = payload;
    },
    setAuthStep: (state, { payload }) => {
      state.authStep = payload;
    },
    setBusiness: (state, { payload }) => {
      state.business = payload;
    },
    setSession: (state, { payload }) => {
      state.session = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setConsulationStep: (state, { payload }) => {
      state.consultationStep = payload;
    },
    userLoggedOut: () => initialState,
  },
});

export const { setModalToggle, setUser, setBusiness, userLoggedOut, setAuthStep, setSession, setConsulationStep } = AuthSlice.actions;

export default AuthSlice.reducer;
