import { Link } from 'react-router-dom';

const Logo = ({ colored, navigateUrl, className }) => {
  return (
    <Link to={navigateUrl ?? `/`} className={`max-w-[150px] lg:max-w-[180px] 2xl:max-w-[225px] ${className}`}>
      {colored ? <img className="w-full object-cover" src={'/Images/logoColor.png'} alt="" /> : <img className="w-full object-cover" src={'/Images/logoWhite.png'} alt="" />}
    </Link>
  );
};
export default Logo;

export const SmallLogo = ({ colored, className }) => {
  return (
    <div className={`max-w-[40px] max-h-[40px] ${className}`}>
      {colored ? <img className="w-full object-cover" src={'/Images/smallLogo.png'} alt="" /> : <img className="w-full object-cover" src={'/Images/smallLogo.png'} alt="" />}
    </div>
  );
};
// auth screen fixed images
export const AuthVectorImage = () => {
  return (
    <>
      {/* layout corner vector images  left*/}
      <div className=" max-w-[100px] md:max-w-[181px] max-h-[181px] -z-0 fixed left-0 bottom-0 md:bottom-[60px] fill-secondary/50">
        <svg width="182" height="243" viewBox="0 0 182 243" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.000488281 0L163.329 101.231C172.071 106.633 178.311 115.285 180.677 125.285C183.043 135.286 181.341 145.817 175.945 154.563L120.984 243.449H0.000488281V0Z" />
        </svg>
      </div>
      {/* layout corner vector images right */}

      <div className=" max-w-[100px] md:max-w-[181px] max-h-[181px] -z-0 fixed right-0 top-0 fill-secondary/50">
        <svg width="182" height="243" viewBox="0 0 182 243" xmlns="http://www.w3.org/2000/svg">
          <path d="M181.718 243L18.3902 141.769C9.6474 136.367 3.40729 127.715 1.0414 117.715C-1.3245 107.714 0.377491 97.1833 5.77335 88.4371L60.7346 -0.448883H181.718V243Z" />
        </svg>
      </div>
      {/* layout corner vector images */}
    </>
  );
};
