import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  jobs: null,
  singleJob: null,
  matchesJobs: null,
};

export const JobSlice = createSlice({
  name: 'jobSlice',
  initialState,
  reducers: {
    setJobs: (state, { payload }) => {
      state.jobs = payload;
    },
    setMatchesJobs: (state, { payload }) => {
      state.matchesJobs = payload;
    },
    setAddJob: (state, { payload }) => {
      state.jobs?.jobs.push(payload);
    },
    setSingleJob: (state, { payload }) => {
      state.singleJob = payload;
    },

    setDeleteJob: (state, { payload }) => {
      const updatedJobs = state.jobs?.jobs?.filter(job => job._id !== payload._id);
      state.jobs.jobs = updatedJobs;
    },

    setUpdateJob: (state, { payload }) => {
      const jobIndex = state.jobs?.jobs?.findIndex(job => job?._id === payload?._id);
      console.log({ jobIndex, payload }, '@status redux');
      if (jobIndex >= 0) {
        state.jobs.jobs[jobIndex] = payload;
      }
    },
  },
});

export const { setJobs, setUpdateJob, setAddJob, setSingleJob, setDeleteJob, setMatchesJobs } = JobSlice.actions;

export default JobSlice.reducer;
