class EnumsController {
  static MATCH_REQ_STATUS = {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    CANCELLED: 'cancelled',
  };
  static ACCOUNT_STATUS = {
    APPROVED: 'approved',
    PENDING: 'pending',
    BLOCK: 'block',
    DRAFT: 'draft',
    REJECTED: 'rejected',
  };
  static CHAT_STATUS = {
    SHORTLISTED: 'Shortlisted',
    SUBMITTED: 'Submitted',
    REJECTED: 'Rejected',
    INTERVIEW: 'Interview',
    OFFER: 'Offer',
    HIRED: 'Hired',
  };
}

export default EnumsController;
